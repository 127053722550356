import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getApolloClient } from '~/lazy_apollo';
import debounce from 'lodash/debounce';

import Select from './Select';

const SelectAsync = (props) => {
  const { allowEmpty, limit, query, sortBy, sortDir, toOptions, variables, ...selectProps } = props;
  const loadOptions = debounce(useCallback((input, callback) => {
    const searchTerm = (input || '').trim();
    if (!allowEmpty && searchTerm.length === 0) {
      callback([]);
      return;
    }
    getApolloClient().then(client => client.query({
      fetchPolicy: 'network-only',
      query,
      variables: {
        pagination: {
          limit,
          offset: 0,
          searchTerm,
          sortBy,
          sortDir,
        },
        ...variables,
      },
    }).then(({ data }) => {
      const options = toOptions(data, input);
      callback(options);
    }));
  }, [allowEmpty, query, limit, sortBy, sortDir, variables, toOptions]), 300);
  const filterOption = useCallback(option => option, []);
  return (
    <Select
      async
      defaultOptions
      helperText={props.helperText}
      isRequiredTitleLabel={props.isRequiredTitleLabel}
      filterOption={filterOption}
      loadOptions={loadOptions}
      {...selectProps}
    />
  );
};

SelectAsync.defaultProps = {
  allowEmpty: false,
  helperText: null,
  isRequiredTitleLabel: false,
  limit: 10,
  noOptionsMessage: undefined,
  sortBy: null,
  sortDir: null,
  value: null,
  variables: {},
};

SelectAsync.propTypes = {
  allowEmpty: PropTypes.bool,
  helperText: PropTypes.string,
  isRequiredTitleLabel: PropTypes.bool,
  limit: PropTypes.number,
  // MaybeFormattedMessage
  noOptionsMessage: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  toOptions: PropTypes.func.isRequired,
  // Reuse Select.value's type when converting to TypeScript
  value: PropTypes.any,
  variables: PropTypes.object,
};

export default SelectAsync;
