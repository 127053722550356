import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import useVariable from '../../../utils/useVariable';

import FormGroup from './FormGroup';
import SelectAsync from './SelectAsync';

const SelectAsyncGroup = (props) => {
  const { field, isMulti, validate, initialOption, ...selectProps } = props;
  const { value, setValue } = useField(field, { validate });

  // react-select wants values in the form of { label, value }, so we cache the labels for lazy-loaded options
  const [cachedOptions, setCachedOptions] = useVariable(initialOption);
  let selectedValue = null;

  // When using isMulti, expect value to have array of ids to set the `selectedValue`
  if (isMulti) {
    selectedValue = value.map(v => ({
      label: cachedOptions[v],
      value: v,
    }));
  } else if (cachedOptions[value]) {
    selectedValue = { label: cachedOptions[value], value };
  }

  return (
    <FormGroup>
      <SelectAsync
        isMulti={isMulti}
        name={field}
        onChange={(params, option) => {
          // disable removing if the item chip is not clearable and trying to remove it.
          if (option.removedValue && !selectProps.isChipClearable) return;

          let newValue = isMulti ? [] : null;
          if (params) {
            if (isMulti) {
              // Multi params: [{ label: "Label 1", value: "Value 1" }]
              newValue = params.map(({ label, value: v }) => {
                cachedOptions[v] = label;
                setCachedOptions(cachedOptions);
                return v;
              });
            } else {
              // Single params: { label: "Label", value: "Value" }
              cachedOptions[params.value] = params.label;
              setCachedOptions(cachedOptions);
              newValue = params.value;
            }
          }
          setValue(newValue);
        }}
        value={selectedValue}
        {...selectProps}
      />
    </FormGroup>
  );
};

SelectAsyncGroup.defaultProps = {
  initialOption: {},
  isMulti: false,
  validate: null,
};

SelectAsyncGroup.propTypes = {
  field: PropTypes.string.isRequired,
  initialOption: PropTypes.object,
  isMulti: PropTypes.bool,
  validate: PropTypes.func,
};

export default SelectAsyncGroup;
