import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../admin/shared/BasicModal';
import { closeReviewSectionModal } from '../ModalActions';
import { withRestaurant } from '../../utils/withRestaurant';
import RestaurantReviewForm from '../../consumer/reviews/RestaurantReviewFrom/RestaurantReviewForm';

const ReviewSectionModal = ({ restaurant }) => {
  const showReviewSectionModal = useSelector(state => state.modals.showReviewSectionModal);
  const selectedLocationId = useSelector(state => state.modals.locationId);
  const selectedMenuItemId = useSelector(state => state.modals.menuItemId);
  const dispatch = useDispatch();

  if (!restaurant || !showReviewSectionModal) {
    return null;
  }
  return (
    <BasicModal
      closeModal={() => dispatch(closeReviewSectionModal())}
      show={showReviewSectionModal}
      size="sm"
      PaperProps={{
        'aria-label': 'Add Your Review',
      }}
    >
      <RestaurantReviewForm
        restaurant={restaurant}
        selectedLocationId={selectedLocationId}
        selectedMenuItemId={selectedMenuItemId}
      />
    </BasicModal>
  );
};

ReviewSectionModal.propTypes = {
  restaurant: PropTypes.object.isRequired,
};

export default withRestaurant(ReviewSectionModal);
