export const FORM_NAME = 'Add Your Review';
export const ROLES = {
  comboBox: 'combobox',
  listBox: 'listbox',
  menuItem: 'menuitem',
};
export const SELECTED_ITEM_CLASS = 'Mui-selected';

const processAddedNodeMutation = (mutation: MutationRecord) => {
  if (mutation.addedNodes.length <= 0) return;

  mutation.addedNodes.forEach((addedNode) => {
    if (addedNode instanceof Text) return;

    const addedElement = addedNode as Element;
    const menuItems = addedElement.parentElement?.querySelectorAll(`[role*="${ROLES.menuItem}"]`);

    if (menuItems && menuItems.length > 0) {
      menuItems.forEach(menuItem => menuItem.setAttribute('role', ROLES.listBox));
    }
  });
};

const processAttributeChangeMutation = (mutation: MutationRecord) => {
  if (mutation.attributeName !== 'class') return;

  const targetNode = mutation.target as Element;
  const targetRole = targetNode.getAttribute('role');
  const isTargetNodeListItem = targetRole && [ROLES.listBox, ROLES.menuItem].includes(targetRole);

  if (!isTargetNodeListItem) return;

  const isTargetNodeSelected = targetNode.getAttribute('class')?.includes(SELECTED_ITEM_CLASS);

  if (isTargetNodeSelected) {
    targetNode.setAttribute('aria-selected', 'true');
  } else if (targetNode.hasAttribute('aria-selected')) {
    targetNode.removeAttribute('aria-selected');
  }
};

export const setDefaultSelectInputAttributes = () => {
  const selectInputs = document.querySelectorAll('input[id^=react-select][aria-autocomplete=list]');

  selectInputs.forEach((selectInput) => {
    selectInput.setAttribute('role', ROLES.comboBox);
    selectInput.setAttribute('aria-expanded', 'false');
  });
};

export const setFormElementMutationObserver = () => {
  const form = document.querySelector(`form[aria-label="${FORM_NAME}"]`);

  const callback = (mutationList: MutationRecord[]) => {
    mutationList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        processAddedNodeMutation(mutation);
      }
      if (mutation.type === 'attributes') {
        processAttributeChangeMutation(mutation);
      }
    });
  };

  const observer = new MutationObserver(callback);

  if (form) observer.observe(form, { attributes: true, childList: true, subtree: true });

  return observer;
};

export const updateAttributesOnMenuToggle = (inputName: string, expanded: boolean) => {
  const selectInputs = document.querySelectorAll(`input[id^=react-select][aria-autocomplete=list][aria-label^="${inputName}"]`);

  selectInputs.forEach((selectInput) => {
    selectInput.setAttribute('role', ROLES.comboBox);
    selectInput.setAttribute('aria-expanded', `${expanded}`);
  });
};
